import React, {useEffect, useRef, useState} from 'react';
import './AddFood.css';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import TextFieldStyles from '../../utils/textfield/fieldstyles';
import Space from '../../components/Space/Space';
import DayTimePicker, {type DayTimePickerHandle} from '../../components/DayTimePicker/DayTimePicker';
import CheckBox from '../../components/CheckBox/CheckBox';
import {type TaxModel} from '../../models/tax';
import ListView, {type ListViewHandle} from '../../components/ListView/ListView';
import RadioButton from '../../components/RadioButton/RadioButton';
import ImageImport, {type ImageImportHandle} from '../../components/ImageImport/ImageImport';
import RelToast, {type RelToastHandle} from '../../components/RelToast/RelToast';
import OutlineButton from '../../components/OutlineButton/OutlineButton';
import HorButtons, {type HorButtonsHandle} from '../../components/HorButtons/HorButtons';
import {usePluginContext} from '../../context/pluginContext';
import {type RestaurantModel} from '../../models/restaurants';
import DateTimeFrto, {type DateTimeFrtoHandle} from '../../components/DateTimeFrto/DateTimeFrto';
import {type ConditionModel} from '../../models/condition';
import ConditionsService from '../../services/conditions';
import {type CoalitionModel} from '../../models/coalitions';
import CoalitionsService from '../../services/coalitions';
import {type CategoryModel} from '../../models/categories';
import CategoryService from '../../services/categories';
import {type MenusModel} from '../../models/menus';
import MenusService from '../../services/menus';
import {type CustomizationModel} from '../../models/customizations';
import CustomizationService from '../../services/customizations';
import AddonService from '../../services/addons';
import {type AddonModel} from '../../models/addons';
import TaxService from '../../services/tax';
import MaterialsService from '../../services/materials';
import {type MaterialsModel} from '../../models/materials';
import Button from '../../components/Button/Button';
import IconButton from '../../components/IconButton/IconButton';
import Delete from '../../assets/icons/delete';
import PasteSvg from '../../assets/icons/paste';
import FoodsService from '../../services/foods';
import {useAuthContext} from '../../context/authContext';
import {RestaurantContext} from '../../context/restContext';

type Fields = {
	id: number;
	label: string;
};

type Variations = {
	customiIds: string[];
	addonIds: string[];
	name: string;
	isDefault: boolean;
	price: number;
	discountPrice: number;
	hasTax: boolean;
	taxId: string;
	useOverallTax: boolean;
	availability: Array<{
		day: string;
		st: number;
		et: number;
	}>;
	servingInfo: number;
	calorieCount: string;
	portionSize: string;
	preparationTime: number;
	hasOffer: boolean;
	offerValidity: string;
	offerNote: string;
	hasStock: boolean;
	noOfStock: number;
	ingredients: Array<{
		note: string;
		matId: string;
		isWeight: boolean;
		weight: number;
		nos: number;
		wastage: {
			hasWastage: boolean;
			isWeight: boolean;
			weight: number;
			nos: number;
		};
	}>;
};

export type Ingredients = {
	note: string;
	matName: string;
	matId: string;
	isWeight: boolean;
	weight: number;
	nos: number;
	wastage: {
		hasWastage: boolean;
		isWeight: boolean;
		weight: number;
		nos: number;
	};
};

const AddFood = () => {
	const authRes = useAuthContext();
	const plugins = usePluginContext();

	const labelBetWeenContainerSize = 15;
	const betWeenContainerSize = 25;

	const prevScrollY = useRef(0);
	const [isToolHide, setToolHide] = useState<boolean>(false);
	const relToast = useRef<RelToastHandle>(null);
	const fooTypeData = () => ['Normal', 'Combo', 'Free Claim'];
	const delIcon = new Delete();
	const pasteIcon = new PasteSvg();

	// Fetching data
	const [coalData, setCoalData] = useState<CoalitionModel[]>([]);
	const [cateData, setCateData] = useState<CategoryModel[]>([]);
	const [menuData, setMenuData] = useState<MenusModel[]>([]);
	const [varsData, setVarsData] = useState<Variations[]>([]);
	const [custData, setCustData] = useState<CustomizationModel[]>([]);
	const [adoData, setAdoData] = useState<AddonModel[]>([]);
	const [taxData, setTaxData] = useState<TaxModel[]>([]);
	const [matData, setMatData] = useState<MaterialsModel[]>([]);

	// Loading
	const [isLoading, setLoading] = useState<boolean>(false);
	const [isCondLoading, setCondLoading] = useState<boolean>(true);
	const [isCoalLoading, setCoalLoading] = useState<boolean>(true);
	const [isMenuLoading, setMenuLoading] = useState<boolean>(true);
	const [isCateLoading, setCateLoading] = useState<boolean>(true);
	const [isCustLoading, setCustLoading] = useState<boolean>(true);
	const [isAdoLoading, setAdoLoading] = useState<boolean>(true);
	const [isTaxLoading, setTaxLoading] = useState<boolean>(true);
	const [isMatLoading, setMatLoading] = useState<boolean>(true);

	// Refs
	const nameField = useRef<TextFieldHandle>(null);
	const noteField = useRef<TextFieldHandle>(null);
	const restRef = useRef<ListViewHandle>(null);
	const restTypRef = useRef<ListViewHandle>(null);
	const coalRef = useRef<ListViewHandle>(null);
	const cateRef = useRef<ListViewHandle>(null);
	const menuRef = useRef<ListViewHandle>(null);
	const variationRef = useRef<ListViewHandle>(null);
	const restTypesRef = useRef<HorButtonsHandle>(null);
	const freeCaimFieldsRef = Array.from({length: 10}, () => useRef<TextFieldHandle>(null));
	const availRef = useRef<DayTimePickerHandle>(null);
	const photoImg = useRef<ImageImportHandle>(null);
	const comboRef = useRef<DateTimeFrtoHandle>(null);
	const freeClaValRef = useRef<DateTimeFrtoHandle>(null);
	const taxRef = useRef<ListViewHandle>(null);
	const matRef = useRef<ListViewHandle>(null);

	const nameVarField = useRef<TextFieldHandle>(null);
	const priceVarField = useRef<TextFieldHandle>(null);
	const stockVarField = useRef<TextFieldHandle>(null);
	const serInfoVarField = useRef<TextFieldHandle>(null);
	const caloCouVarField = useRef<TextFieldHandle>(null);
	const porSizeVarField = useRef<TextFieldHandle>(null);
	const prepTimVarField = useRef<TextFieldHandle>(null);
	const disPriceVarField = useRef<TextFieldHandle>(null);
	const offNoteVarField = useRef<TextFieldHandle>(null);
	const offValiRef = useRef<DateTimeFrtoHandle>(null);
	const weighField = useRef<TextFieldHandle>(null);
	const wasWeighField = useRef<TextFieldHandle>(null);

	// Values
	const [selFoType, setSelFoType] = useState<number>(0);
	const [selRest, setSelRest] = useState<number>(-1);
	const [selCondIndex, setSelCondIndex] = useState<number>(-1);
	const [fields, setFields] = useState<Fields[]>([]);
	const [condData, setCondData] = useState<ConditionModel[]>([]);
	const [selCoals, setSelCoals] = useState<number[]>([]);
	const [selCate, setSelCate] = useState<number>(-1);
	const [selMenus, setSelMenus] = useState<number[]>([]);
	const [selCusts, setSelCusts] = useState<number[]>([]);
	const [selAdos, setSelAdos] = useState<number[]>([]);
	const [selIngred, setSelIngred] = useState<Ingredients[]>([]);
	const [selRestType, setSelRestType] = useState<number[]>([]);
	const [hasStock, setHasStock] = useState<boolean>(true);
	const [taxMode, setTaxMode] = useState<number>(2);
	const [selTax, setSelTax] = useState<number>(-1);
	const [hasOffer, setHasOffer] = useState<boolean>(false);
	const [selMat, setSelMat] = useState<number>(-1);
	const [isWeight, setIsWeight] = useState<boolean>(true);
	const [isDefault, setIsDefault] = useState<boolean>(true);

	useEffect(() => {
		const fetchData = async () => {
			await Promise.all([
				getMenus(),
				getCoalitions(),
				getCategory(),
				getCustomizations(),
				getAddons(),
				getTax(),
				getMaterials(),
			]);
		};

		void fetchData();
	}, []);

	useEffect(() => {
		// When freeclaim is choosed then only fetch the data
		if (selFoType === 2) {
			void getConditions();
		}
	}, [selFoType]);

	/// DATA---->

	const getMenus = async () => {
		setMenuLoading(true);

		await new MenusService().get().then(val => {
			if (val.hasError) {
				relToast.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setMenuData(val.res!.data!);
			}
		}).finally(() => {
			setMenuLoading(false);
		});
	};

	const getConditions = async () => {
		setCondLoading(true);

		await new ConditionsService().get().then(val => {
			if (val.hasError) {
				relToast.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setCondData(val.res!.data!);
			}
		}).finally(() => {
			setCondLoading(false);
		});
	};

	const getCoalitions = async () => {
		setCoalLoading(true);

		await new CoalitionsService().get().then(val => {
			if (val.hasError) {
				relToast.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setCoalData(val.res!.data!);
			}
		}).finally(() => {
			setCoalLoading(false);
		});
	};

	const getCategory = async () => {
		setCateLoading(true);

		await new CategoryService().get().then(val => {
			if (val.hasError) {
				relToast.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setCateData(val.res!.data!);
			}
		}).finally(() => {
			setCateLoading(false);
		});
	};

	const getCustomizations = async () => {
		setCustLoading(true);

		await new CustomizationService().get().then(val => {
			if (val.hasError) {
				relToast.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setCustData(val.res!.data!);
			}
		}).finally(() => {
			setCustLoading(false);
		});
	};

	const getAddons = async () => {
		setAdoLoading(true);

		await new AddonService().get().then(val => {
			if (val.hasError) {
				relToast.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setAdoData(val.res!.data!);
			}
		}).finally(() => {
			setAdoLoading(false);
		});
	};

	const getTax = async () => {
		setTaxLoading(true);

		await new TaxService().get().then(val => {
			if (val.hasError) {
				relToast.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setTaxData(val.res!.data!);
			}
		}).finally(() => {
			setTaxLoading(false);
		});
	};

	const getMaterials = async () => {
		setMatLoading(true);

		await new MaterialsService().get().then(val => {
			if (val.hasError) {
				relToast.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setMatData(val.res!.data!);
			}
		}).finally(() => {
			setMatLoading(false);
		});
	};

	/// FUNCTIONS---->

	const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
		if (e.currentTarget) {
			const {scrollTop, scrollHeight, clientHeight} = e.currentTarget;
			const isNearBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 1;

			if ((scrollTop > prevScrollY.current) && !isToolHide && !isNearBottom) {
				setToolHide(true);
			} else if ((scrollTop < prevScrollY.current) && isToolHide && !isNearBottom) {
				setToolHide(false);
			}

			prevScrollY.current = scrollTop;
		}
	};

	const onPrepareFields = (j: number) => {
		const schemLen = Object.keys(condData[j].schema);

		const fie: Fields[] = [];
		for (let i = 0; i < schemLen.length; i++) {
			fie.push({id: i, label: schemLen[i]});
		}

		setFields(fie);
	};

	/// ELEMENTS---->

	const loadingScreen = () => isLoading && <div className='AddFood-container-row' style={{width: '180px', justifyContent: 'flex-end', alignItems: 'center'}}>
		<label className='loading-title'>Please Wait....</label>
		<Space size={5} isAutoResize={false}></Space>
		<div className='cupertino-spinner' style={{height: '15px', width: '15px'}}></div>
	</div>;

	const comboOrFree = () => (selFoType !== 0 && <div className='AddFood-container-column'>
		<Space size={betWeenContainerSize} isAutoResize={false}></Space>
		{selFoType === 1 && <div className='AddFood-container-column'>
			<label className='AddFood-label'>Combo Validity</label>
			<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
			<DateTimeFrto ref={comboRef}></DateTimeFrto>
		</div>}
		{selFoType === 2 && <Space size={5} isAutoResize={true}></Space>}
		{selFoType === 2 && <div className='AddFood-container-column'>
			<label className='AddFood-label'>Free Claim Validity</label>
			<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
			<DateTimeFrto ref={freeClaValRef}></DateTimeFrto>
			<Space size={45} isAutoResize={false}></Space>
			<div className='AddFood-container-row'>
				{selCondIndex !== -1 && <div className='AddFood-container-column' style={{width: '30%', marginRight: '5%'}}>
					<label className='deliveryCharge-list-adapter-perc'>Fill the below paramaters for activate the condition</label>
					<Space size={15} isAutoResize={false}></Space>
					<div className='deliveryCharge-cond-field-list' style={{width: '100%'}}>
						{<div className='deliveryCharge-left-list'>
							<ListView data={fields} adapter={function (i, item): JSX.Element {
								const itemData = item as Fields;
								return <div className='deliveryCharge-list-adapter'>
									<label className='deliveryCharge-list-adapter-ti-label'>{fields[i].label}</label>
									<Space size={5} isAutoResize={true}></Space>
									<TextField ref={freeCaimFieldsRef[i]} isLoading={isCondLoading}
										label={`Enter the ${fields[i].label}`}
										inputType={TextFieldInputTypes.floatnumber} options={{maxLength: 5}}></TextField>
								</div>;
							}} adapterHeight={55} />
						</div>}
					</div>
				</div>}
				<div className='AddFood-container-column' style={{width: selCondIndex === -1 ? '100%' : '65%', height: '200px'}}>
					<label className='deliveryCharge-list-adapter-perc'>Select the Freeclaim condition (user can free claim only when applicable to these conditions)</label>
					<Space size={15} isAutoResize={false}></Space>
					<div className='listview-wrapper'>
						{isCondLoading ? (<ListView dummy={5} adapter={function (i, item): JSX.Element {
							return <div className='listview-item-adapter'>
								<label className='listview-item-adapter-index-load'>00</label>
								<Space size={5} isAutoResize={true}></Space>
								<div className='listview-item-adapter-card-load'>
									<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
								</div>
							</div>;
						}} selItemIndex={-1} adapterHeight={60} />) : (<ListView data={condData} adapter={function (i, item): JSX.Element {
							const itemData = item as ConditionModel;
							return <div className='listview-item-adapter'>
								<label className='listview-item-adapter-index'>{i + 1}</label>
								<Space size={5} isAutoResize={true}></Space>
								<div className='listview-item-adapter-card'>
									<label className='listview-item-adapter-title'>{itemData.name}</label>
									<RadioButton title='' index={i} selIndex={selCondIndex} onClick={i => {
										setSelCondIndex(i);
										onPrepareFields(i);
									}}></RadioButton>
									<Space size={5} isAutoResize={true}></Space>
								</div>
							</div>;
						}} selItemIndex={selCondIndex} onSelectItem={i => {
							setSelCondIndex(i);
							onPrepareFields(i);
						}} adapterHeight={60} />)}
					</div>
				</div>
			</div>
		</div>}
	</div>);

	const coalAndCategory = () => <div className='AddFood-container-row'>
		<div className='AddFood-container-column'>
			<label className='AddFood-label'>Select the Coalitions (primary category)</label>
			<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
			{isCoalLoading ? (<div className='listview-wrapper'>
				<ListView dummy={3} adapter={function (i, item): JSX.Element {
					return <div className='listview-item-adapter'>
						<label className='listview-item-adapter-index-load'>00</label>
						<Space size={5} isAutoResize={true}></Space>
						<div className='listview-item-adapter-card-load'>
							<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
						</div>
					</div>;
				}} selItemIndex={-1} adapterHeight={35} />
			</div>) : (<div className='listview-wrapper'>
				<ListView ref={coalRef} data={coalData} adapter={function (i: number, item: CoalitionModel | number): JSX.Element {
					const itemData = item as CoalitionModel;
					return <div className='listview-item-adapter'>
						<label className='listview-item-adapter-index'>{i + 1}</label>
						<Space size={5} isAutoResize={true}></Space>
						<div className='listview-item-adapter-card'>
							<label className='listview-item-adapter-title'>{`${itemData.name}`}</label>
							<CheckBox title='' initVal={selCoals.includes(i)} ovrVal={selCoals.includes(i)} onClick={(b: boolean) => {
								if (!isLoading) {
									if (selCoals.includes(i)) {
										setSelCoals(selCoals.filter(v => v !== i));
									} else {
										setSelCoals([...selCoals, i]);
									}
								}
							}}></CheckBox>
							<Space size={5} isAutoResize={true}></Space>
						</div>
					</div>;
				}} adapterHeight={35} selItemIndex={selCoals} onSelectItem={(i: number) => {
					if (!isLoading) {
						if (selCoals.includes(i)) {
							setSelCoals(selCoals.filter(v => v !== i));
						} else {
							setSelCoals([...selCoals, i]);
						}
					}
				}} />
			</div>)}
		</div>
		<Space size={5} isAutoResize={true}></Space>
		<div className='AddFood-container-column'>
			<label className='AddFood-label'>Select the category (secondary category)</label>
			<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
			{isCateLoading ? (<div className='listview-wrapper'>
				<ListView dummy={3} adapter={function (i, item): JSX.Element {
					return <div className='listview-item-adapter'>
						<label className='listview-item-adapter-index-load'>00</label>
						<Space size={5} isAutoResize={true}></Space>
						<div className='listview-item-adapter-card-load'>
							<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
						</div>
					</div>;
				}} selItemIndex={-1} adapterHeight={35} />
			</div>) : (<div className='listview-wrapper'>
				<ListView ref={cateRef} data={cateData} adapter={function (i: number, item: CategoryModel | number): JSX.Element {
					const itemData = item as CategoryModel;
					return <div className='listview-item-adapter'>
						<label className='listview-item-adapter-index'>{i + 1}</label>
						<Space size={5} isAutoResize={true}></Space>
						<div className='listview-item-adapter-card'>
							<label className='listview-item-adapter-title'>{itemData.name}</label>
							<RadioButton title='' index={i} selIndex={selCate} onClick={(r: number) => {
								if (!isLoading) {
									setSelCate(r);
								}
							}}></RadioButton>
							<Space size={5} isAutoResize={true}></Space>
						</div>
					</div>;
				}} adapterHeight={35} selItemIndex={selCate} onSelectItem={(i: number) => {
					if (!isLoading) {
						setSelCate(i);
					}
				}} />
			</div>)}
		</div>
	</div>;

	const variOffer = () => selFoType === 0 && <div className='AddFood-container-column'>
		<label className='AddFood-label'>Offer (optional)</label>
		<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
		<div className='AddFood-container-row'>
			<CheckBox title={'has offer?'} initVal={hasOffer} onClick={(b: boolean) => {
				if (!isLoading) {
					setHasOffer(b);
				}
			}}></CheckBox>
			{hasOffer && <Space size={25} isAutoResize={false}></Space>}
			{hasOffer && <TextField ref={disPriceVarField} isLoading={isLoading}
				label={'Discount Price'} styles={TextFieldStyles.indiTitle} placeholder='I.e: 20.5'
				inputType={TextFieldInputTypes.floatnumber}></TextField>}
			{hasOffer && <Space size={15} isAutoResize={false}></Space>}
			{hasOffer && <TextField ref={offNoteVarField} isLoading={isLoading}
				label={'Offer Note'} styles={TextFieldStyles.indiTitle} placeholder='I.e: something about the offer'
				inputType={TextFieldInputTypes.name}></TextField>}
			{hasOffer && <Space size={15} isAutoResize={false}></Space>}
			{hasOffer && <div className='AddFood-container-column' style={{width: '50%'}}>
				<label className='AddFood-label'>Offer Validity</label>
				<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
				<DateTimeFrto ref={offValiRef}></DateTimeFrto>
			</div>}
		</div>
	</div>;

	const variForm = () => <div className='AddFood-container'>
		<div className='AddFood-var-tool'>
			<CheckBox title={'Is Default?'} initVal={isDefault} onClick={(b: boolean) => {
				if (!isLoading) {
					setIsDefault(b);
				}
			}}></CheckBox>
			<Space size={10} isAutoResize={false}></Space>
			<OutlineButton onClick={() => {
				onAddVariation();
			}} isLoading={isLoading} label={'Add Variaition'} padTopBottom='8px' btnColor='rgb(70, 125, 203)'></OutlineButton>
		</div>
		<div className='AddFood-container-column' style={{padding: '1%', width: '98%', height: '98%'}}>
			<div className='AddFood-container-row'>
				<TextField ref={nameVarField} isLoading={isLoading}
					label={'Name'} styles={TextFieldStyles.indiTitle} placeholder='I.e: 1kg'
					inputType={TextFieldInputTypes.name}></TextField>
				<Space size={15} isAutoResize={false}></Space>
				<TextField ref={priceVarField} isLoading={isLoading}
					label={'Price'} styles={TextFieldStyles.indiTitle} placeholder='I.e: 24.7'
					inputType={TextFieldInputTypes.floatnumber}></TextField>
				<Space size={15} isAutoResize={false}></Space>
				<CheckBox title={'Has Stock?'} initVal={hasStock} onClick={(b: boolean) => {
					if (!isLoading) {
						setHasStock(b);
					}
				}}></CheckBox>
				<Space size={15} isAutoResize={false}></Space>
				{hasStock && <TextField ref={stockVarField} isLoading={isLoading}
					label={'No of Stocks'} styles={TextFieldStyles.indiTitle} placeholder='I.e: 100'
					inputType={TextFieldInputTypes.number}></TextField>}
			</div>
			<Space size={15} isAutoResize={false}></Space>
			{selFoType === 1 && <div className='AddFood-container-row'>
				<TextField ref={disPriceVarField} isLoading={isLoading}
					label={'Discount Price'} styles={TextFieldStyles.indiTitle} placeholder='I.e: 20.5'
					inputType={TextFieldInputTypes.floatnumber}></TextField>
				<Space size={15} isAutoResize={false}></Space>
				<TextField ref={offNoteVarField} isLoading={isLoading}
					label={'Offer Note'} styles={TextFieldStyles.indiTitle} placeholder='I.e: something about the offer'
					inputType={TextFieldInputTypes.name}></TextField>

			</div>}
			{selFoType === 1 && <Space size={15} isAutoResize={false}></Space>}
			<div className='AddFood-container-row'>
				<TextField ref={serInfoVarField} isLoading={isLoading}
					label={'Serving Info (no of serve)'} styles={TextFieldStyles.indiTitle} placeholder='I.e: 1'
					inputType={TextFieldInputTypes.number}></TextField>
				<Space size={15} isAutoResize={false}></Space>
				<TextField ref={caloCouVarField} isLoading={isLoading}
					label={'Calorie Count'} styles={TextFieldStyles.indiTitle} placeholder='I.e: Kcl'
					inputType={TextFieldInputTypes.floatnumber}></TextField>
				<Space size={15} isAutoResize={false}></Space>
				<TextField ref={porSizeVarField} isLoading={isLoading}
					label={'Portion Size'} styles={TextFieldStyles.indiTitle} placeholder='I.e: 1kg'
					inputType={TextFieldInputTypes.floatnumber}></TextField>
				<Space size={15} isAutoResize={false}></Space>
				<TextField ref={prepTimVarField} isLoading={isLoading}
					label={'Preparation Time'} styles={TextFieldStyles.indiTitle} placeholder='I.e: 60min'
					inputType={TextFieldInputTypes.floatnumber}></TextField>
			</div>
			<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
			<div className='AddFood-container-row'>
				<div className='AddFood-container-column'>
					<label className='AddFood-label'>Select the Customizations (optional)</label>
					<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
					{isCustLoading ? (<div className='listview-wrapper'>
						<ListView dummy={3} adapter={function (i, item): JSX.Element {
							return <div className='listview-item-adapter'>
								<label className='listview-item-adapter-index-load'>00</label>
								<Space size={5} isAutoResize={true}></Space>
								<div className='listview-item-adapter-card-load'>
									<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
								</div>
							</div>;
						}} selItemIndex={-1} adapterHeight={35} />
					</div>) : (<div className='listview-wrapper'>
						<ListView ref={coalRef} data={custData} adapter={function (i: number, item: CustomizationModel | number): JSX.Element {
							const itemData = item as CustomizationModel;
							return <div className='listview-item-adapter'>
								<label className='listview-item-adapter-index'>{i + 1}</label>
								<Space size={5} isAutoResize={true}></Space>
								<div className='listview-item-adapter-card'>
									<label className='listview-item-adapter-title'>{`${itemData.name}`}</label>
									<CheckBox title='' initVal={selCusts.includes(i)} ovrVal={selCusts.includes(i)} onClick={(b: boolean) => {
										if (!isLoading) {
											if (selCusts.includes(i)) {
												setSelCusts(selCusts.filter(v => v !== i));
											} else {
												setSelCusts([...selCusts, i]);
											}
										}
									}}></CheckBox>
									<Space size={5} isAutoResize={true}></Space>
								</div>
							</div>;
						}} adapterHeight={35} selItemIndex={selCusts} onSelectItem={(i: number) => {
							if (!isLoading) {
								if (selCusts.includes(i)) {
									setSelCusts(selCusts.filter(v => v !== i));
								} else {
									setSelCusts([...selCusts, i]);
								}
							}
						}} />
					</div>)}
				</div>
				<Space size={5} isAutoResize={true}></Space>
				<div className='AddFood-container-column'>
					<label className='AddFood-label'>Select the Addons (optional)</label>
					<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
					{isAdoLoading ? (<div className='listview-wrapper'>
						<ListView dummy={3} adapter={function (i, item): JSX.Element {
							return <div className='listview-item-adapter'>
								<label className='listview-item-adapter-index-load'>00</label>
								<Space size={5} isAutoResize={true}></Space>
								<div className='listview-item-adapter-card-load'>
									<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
								</div>
							</div>;
						}} selItemIndex={-1} adapterHeight={35} />
					</div>) : (<div className='listview-wrapper'>
						<ListView ref={cateRef} data={adoData} adapter={function (i: number, item: AddonModel | number): JSX.Element {
							const itemData = item as AddonModel;
							return <div className='listview-item-adapter'>
								<label className='listview-item-adapter-index'>{i + 1}</label>
								<Space size={5} isAutoResize={true}></Space>
								<div className='listview-item-adapter-card'>
									<label className='listview-item-adapter-title'>{itemData.name}</label>
									<label className='listview-item-adapter-title'>{itemData.price}</label>
									<CheckBox title='' initVal={selAdos.includes(i)} ovrVal={selAdos.includes(i)} onClick={(b: boolean) => {
										if (!isLoading) {
											if (selAdos.includes(i)) {
												setSelAdos(selAdos.filter(v => v !== i));
											} else {
												setSelAdos([...selAdos, i]);
											}
										}
									}}></CheckBox>
									<Space size={5} isAutoResize={true}></Space>
								</div>
							</div>;
						}} adapterHeight={35} selItemIndex={selAdos} onSelectItem={(i: number) => {
							if (!isLoading) {
								if (selAdos.includes(i)) {
									setSelAdos(selAdos.filter(v => v !== i));
								} else {
									setSelAdos([...selAdos, i]);
								}
							}
						}} />
					</div>)}
				</div>
			</div>
			<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
			<div className='AddFood-container-row'>
				<div className='AddFood-container-column'>
					<label className='AddFood-label'>Tax (optional)</label>
					<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
					<label className='AddFood-label-sub'>if the food has unique tax choose individual tax, if not than choose overall tax, if both not then not choose anything</label>
					<Space size={10} isAutoResize={false}></Space>
					<div className='AddFood-container-row'>
						<RadioButton title={'Is individual tax?'} index={0} selIndex={taxMode} onClick={() => {
							if (!isLoading) {
								setTaxMode(0);
							}
						}}></RadioButton>
						<Space size={10} isAutoResize={false}></Space>
						<RadioButton title={'Overall tax?'} index={1} selIndex={taxMode} onClick={() => {
							if (!isLoading) {
								setTaxMode(1);
							}
						}}></RadioButton>
						<Space size={10} isAutoResize={false}></Space>
						<RadioButton title={'no tax?'} index={2} selIndex={taxMode} onClick={() => {
							if (!isLoading) {
								setTaxMode(2);
							}
						}}></RadioButton>
					</div>
					{taxMode === 0 && <Space size={15} isAutoResize={false}></Space>}
					{taxMode === 0 && (isTaxLoading ? (<div className='listview-wrapper'>
						<ListView dummy={3} adapter={function (i, item): JSX.Element {
							return <div className='listview-item-adapter'>
								<label className='listview-item-adapter-index-load'>00</label>
								<Space size={5} isAutoResize={true}></Space>
								<div className='listview-item-adapter-card-load'>
									<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
								</div>
							</div>;
						}} selItemIndex={-1} adapterHeight={35} />
					</div>) : (<div className='listview-wrapper'>
						<ListView ref={taxRef} data={taxData} adapter={function (i: number, item: TaxModel | number): JSX.Element {
							const itemData = item as TaxModel;
							return <div className='listview-item-adapter'>
								<label className='listview-item-adapter-index'>{i + 1}</label>
								<Space size={5} isAutoResize={true}></Space>
								<div className='listview-item-adapter-card'>
									<label className='listview-item-adapter-title'>{`Tax percentage: ${itemData.percentage}%`}</label>
									<RadioButton title='' index={i} selIndex={selTax} onClick={(r: number) => {
										if (!isLoading) {
											setSelTax(r);
										}
									}}></RadioButton>
									<Space size={5} isAutoResize={true}></Space>
								</div>
							</div>;
						}} adapterHeight={35} selItemIndex={selTax} onSelectItem={(i: number) => {
							if (!isLoading) {
								setSelTax(i);
							}
						}} />
					</div>))}
				</div>
				<Space size={5} isAutoResize={true}></Space>
				<div className='AddFood-container-column'>
					<label className='AddFood-label'>Food Availability</label>
					<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
					<label className='AddFood-label-sub'>used determine the availability of the food, like.., this food var available only at night</label>
					<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
					<DayTimePicker ref={availRef} isLoading={isLoading}></DayTimePicker>
				</div>
			</div>
			<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
			{variOffer()}
			<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
			<div className='AddFood-container-column'>
				<label className='AddFood-label'>Ingredients</label>
				<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
				{ingredForm()}
			</div>
		</div>
	</div>;

	const ingredForm = () => <div className='AddFood-container-column'>
		<div className='listview-wrapper' style={{height: '100px'}}>
			{selIngred.length === 0 ? <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
				<label className='AddFood-label-sub' style={{textAlign: 'center'}}>Add the ingredients for this variation</label>
			</div> : <ListView data={selIngred} adapter={function (i: number, item: Ingredients | number): JSX.Element {
				const itemData = item as Ingredients;
				return <div className='Addfood-horlist-item-adapter'>
					<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
						<label className='listview-item-adapter-index' style={{width: '100%'}}>{i + 1}</label>
						<Space size={10} isAutoResize={false}></Space>
						<IconButton icon={delIcon.normal()} onClick={() => {
							setSelIngred(prevData => prevData.filter((item, j) => j !== i));
						}}></IconButton>
					</div>
					<Space size={10} isAutoResize={false}></Space>
					<label className='listview-item-adapter-title'>{`${itemData.matName}`}</label>
					<Space size={5} isAutoResize={false}></Space>
					<label className='listview-item-adapter-title'>{`${itemData.isWeight ? itemData.weight : itemData.nos}`}</label>
				</div>;
			}} adapterHeight={90} isHorizontal={true} />}
		</div>
		<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
		<div className='AddFood-container-row'>
			<div className='AddFood-container-column'>
				{isMatLoading ? (<div className='listview-wrapper'>
					<ListView dummy={3} adapter={function (i, item): JSX.Element {
						return <div className='listview-item-adapter'>
							<label className='listview-item-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='listview-item-adapter-card-load'>
								<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={35} />
				</div>) : (<div className='listview-wrapper'>
					<ListView ref={matRef} data={matData} adapter={function (i: number, item: MaterialsModel | number): JSX.Element {
						const itemData = item as MaterialsModel;
						return <div className='listview-item-adapter'>
							<label className='listview-item-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='listview-item-adapter-card'>
								<label className='listview-item-adapter-title'>{`${itemData.name}`}</label>
								<RadioButton title={''} index={i} selIndex={selMat} onClick={(j: number) => {
									if (!isLoading) {
										setSelMat(j);
									}
								}} ></RadioButton>
								<Space size={5} isAutoResize={true}></Space>
							</div>
						</div>;
					}} adapterHeight={35} selItemIndex={selMat} onSelectItem={(j: number) => {
						if (!isLoading) {
							setSelMat(j);
						}
					}} />
				</div>)}
			</div>
			<Space size={5} isAutoResize={true}></Space>
			<div className='AddFood-container-column'>
				<CheckBox title={'Unit is Weight?'} initVal={isWeight} onClick={(b: boolean) => {
					if (!isLoading) {
						setIsWeight(b);
					}
				}}></CheckBox>
				<Space size={15} isAutoResize={false}></Space>
				<div className='AddFood-container-row'>
					<TextField ref={weighField} isLoading={isLoading}
						label={isWeight ? 'Weight' : 'Nos'} styles={TextFieldStyles.indiTitle} placeholder={isWeight ? 'I.e: 1.5' : 'I.e: 1'}
						inputType={isWeight ? TextFieldInputTypes.floatnumber : TextFieldInputTypes.number}></TextField>
					<Space size={15} isAutoResize={false}></Space>
					<TextField ref={wasWeighField} isLoading={isLoading}
						label={`Wastage (approx ${isWeight ? 'weight)' : 'nos)'}`} styles={TextFieldStyles.indiTitle} placeholder={isWeight ? 'I.e: 1.5' : 'I.e: 1'}
						inputType={isWeight ? TextFieldInputTypes.floatnumber : TextFieldInputTypes.number}></TextField>
				</div>
				<Space size={15} isAutoResize={false}></Space>
				<Button onClick={function (): void {
					onAddIngredient();
				}} label={'Add Ingredient'}></Button>
			</div>
		</div>
	</div>;

	/// FINAL---->

	const onAddVariation = () => {
		if (!isLoading) {
			const field1 = nameVarField.current!.validate();
			const field2 = priceVarField.current!.validate();
			const field3 = stockVarField.current!.validate();
			const field4 = serInfoVarField.current!.validate();
			const field5 = caloCouVarField.current!.validate();
			const field6 = porSizeVarField.current!.validate();
			const field7 = prepTimVarField.current!.validate();

			if (!field1.isValid) {
				relToast.current!.trigger(true, field1.msg);
			} else if (!field2.isValid) {
				relToast.current!.trigger(true, field2.msg);
			} else if (hasStock && !field3.isValid) {
				relToast.current!.trigger(true, field3.msg);
			} else if (!field4.isValid) {
				relToast.current!.trigger(true, field4.msg);
			} else if (!field5.isValid) {
				relToast.current!.trigger(true, field5.msg);
			} else if (!field6.isValid) {
				relToast.current!.trigger(true, field6.msg);
			} else if (!field7.isValid) {
				relToast.current!.trigger(true, field7.msg);
			} else if (taxMode === 0 && selTax === -1) {
				relToast.current!.trigger(true, 'Select the tax percentage');
			} else if (!offerVali().isValidOff) {
				relToast.current!.trigger(true, offerVali().offMsg);
			} else if (availRef.current!.getValue().length === 0) {
				relToast.current!.trigger(true, 'Select the availablity');
			} else {
				finalAddFooVariation();
			}

			// Else if (selIngred.length === 0) {
			// 	relToast.current!.trigger(true, 'Add the ingredients');
			// }
		}
	};

	const offerVali = (): {isValidOff: boolean; offMsg: string} => {
		const offerDit = (hasOffer && selFoType === 0) || selFoType === 1;

		if (offerDit) {
			const field8 = disPriceVarField.current!.validate();
			const field9 = offNoteVarField.current!.validate();

			if (!field8.isValid) {
				relToast.current!.trigger(true, field8.msg);
				return {isValidOff: false, offMsg: ''};
			}

			if (!field9.isValid) {
				return {isValidOff: false, offMsg: field9.msg};
			}

			if (hasOffer && offValiRef.current!.getValue() === undefined) {
				return {isValidOff: false, offMsg: 'Select offer validity'};
			}

			return {isValidOff: true, offMsg: ''};
		}

		return {isValidOff: true, offMsg: ''};
	};

	const freeVali = (): {isValidFree: boolean; offMsg: string} => {
		if (selFoType === 2 && freeClaValRef.current!.getValue() === undefined) {
			freeClaValRef.current!.focus();
			return {isValidFree: false, offMsg: 'Add the validity for freeclaim'};
		}

		if (selFoType === 2 && selCondIndex === -1) {
			return {isValidFree: false, offMsg: 'Select the condition for the freeclaim'};
		}

		if (selFoType === 2 && selCondIndex !== -1 && Object.keys(condData[selCondIndex].schema).length === 0) {
			return {isValidFree: false, offMsg: 'Freeclaim values is mandatory'};
		}

		return {isValidFree: true, offMsg: ''};
	};

	const finalAddFooVariation = () => {
		if (!varsData.map(v => v.name).includes(nameVarField.current!.getValue())) {
			const oldProc = isDefault ? varsData.map(v => {
				if (v.isDefault) {
					v.isDefault = false;
					return v;
				}

				return v;
			}) : varsData;

			setVarsData([...oldProc, {
				customiIds: custData.filter((v, i) => selCusts.includes(i)).map(v => v._id),
				addonIds: adoData.filter((v, i) => selAdos.includes(i)).map(v => v._id),
				name: nameVarField.current!.getValue(),
				isDefault,
				price: parseFloat(priceVarField.current!.getValue()),
				discountPrice: hasOffer || selFoType === 1 ? parseFloat(disPriceVarField.current!.getValue()) : 0,
				hasTax: taxMode === 0 || taxMode === 1,
				taxId: taxMode === 0 ? taxData[selTax]._id : '',
				useOverallTax: taxMode === 1,
				availability: availRef.current!.getValue(),
				servingInfo: parseFloat(serInfoVarField.current!.getValue()),
				calorieCount: caloCouVarField.current!.getValue(),
				portionSize: porSizeVarField.current!.getValue(),
				preparationTime: parseFloat(prepTimVarField.current!.getValue()),
				hasOffer,
				offerValidity: hasOffer && selFoType === 0 ? offValiRef.current!.getValue()! : '',
				offerNote: hasOffer || selFoType === 1 ? offNoteVarField.current!.getValue() : '',
				hasStock,
				noOfStock: hasStock ? Number(stockVarField.current!.getValue()) : 0,
				ingredients: selIngred.length === 0 ? [] : selIngred.map(v => ({
					note: v.note,
					matId: v.matId,
					isWeight: v.isWeight,
					weight: v.weight,
					nos: v.nos,
					wastage: {
						hasWastage: v.wastage.hasWastage,
						isWeight: v.wastage.isWeight,
						weight: v.wastage.weight,
						nos: v.wastage.nos,
					},
				})),
			}]);
		}
	};

	const onAddIngredient = () => {
		if (!isLoading) {
			const weiFie1 = weighField.current!.validate();
			const weiFie2 = wasWeighField.current!.validate();

			if (selMat === -1) {
				relToast.current!.trigger(true, 'Select the material');
				matRef.current!.focus();
			} else if (!weiFie1.isValid || !weiFie2.isValid) {
				relToast.current!.trigger(true, weiFie2.isValid ? 'Add the weight or nos' : 'Add the wastage weight or nos');
			} else if (selIngred.some(v => v.matName === matData[selMat].name)) {
				relToast.current!.trigger(true, 'Ingredient already added');
			} else {
				const hasWastage = wasWeighField.current!.getValue() !== '0';
				setSelIngred([...selIngred, {
					note: 'n/a',
					matName: matData[selMat].name,
					matId: matData[selMat]._id,
					isWeight,
					weight: isWeight ? parseFloat(weighField.current!.getValue()) : 0,
					nos: isWeight ? 0 : parseFloat(weighField.current!.getValue()),
					wastage: {
						hasWastage,
						isWeight,
						weight: hasWastage ? (isWeight ? parseFloat(wasWeighField.current!.getValue()) : 0) : 0,
						nos: hasWastage ? (isWeight ? 0 : parseFloat(wasWeighField.current!.getValue())) : 0,
					},
				}]);
			}
		}
	};

	const onAddFood = async (rests: RestaurantModel[]) => {
		if (!isLoading) {
			setLoading(true);

			const field1 = nameField.current!.validate();
			const field2 = noteField.current!.validate();

			if (!field1.isValid) {
				relToast.current!.trigger(true, field1.msg);
			} else if (!field2.isValid) {
				relToast.current!.trigger(true, field2.msg);
			} else if (selRest === -1) {
				relToast.current!.trigger(true, 'Select the restaurant to list this food');
				restRef.current!.focus();
			} else if (selRestType.length === 0) {
				relToast.current!.trigger(true, 'Select the services to list this food');
				restTypRef.current!.focus();
			} else if (selMenus.length === 0) {
				relToast.current!.trigger(true, 'Select the menu');
				menuRef.current!.focus();
			} else if (selFoType === 1 && comboRef.current!.getValue() === undefined) {
				relToast.current!.trigger(true, 'Add the validity for combo');
				comboRef.current!.focus();
			} else if (!freeVali().isValidFree) {
				relToast.current!.trigger(true, freeVali().offMsg);
			} else if (selCoals.length === 0) {
				relToast.current!.trigger(true, 'Select the colatition');
				coalRef.current!.focus();
			} else if (selCate === -1) {
				relToast.current!.trigger(true, 'Select the category');
				cateRef.current!.focus();
			} else if (photoImg.current!.selFiles().length === 0) {
				relToast.current!.trigger(true, 'Select atleast a image');
				photoImg.current!.focus();
			} else if (varsData.length === 0) {
				relToast.current!.trigger(true, 'Add atleast a variation');
			} else {
				await uploadData(rests);
			}

			setLoading(false);
		}
	};

	const uploadData = async (rests: RestaurantModel[]) => {
		const formData = new FormData();

		const cVal: Record<string, unknown> = {};
		const keys = selFoType === 2 ? Object.keys(condData[selCondIndex].schema) : [];
		for (let i = 0; i < keys.length; i++) {
			cVal[keys[i]] = parseFloat(freeCaimFieldsRef[i].current!.getValue());
		}

		formData.append('data', JSON.stringify({
			name: nameField.current!.getValue(),
			note: noteField.current!.getValue(),
			coalitionIds: selCoals.map(v => coalData[v]._id),
			menuIds: selMenus.map(v => menuData[v]._id),
			categoryId: cateData[selCate]._id,
			restId: rests[selRest]._id,
			restTypeIds: rests[selRest].restTypes.filter((v, i) => selRestType.includes(i)).map(v => v._id),
			isCombo: selFoType === 1,
			comboValidity: selFoType === 1 ? comboRef.current!.getValue()! : null,
			isFreeClaim: selFoType === 2,
			freeValidity: selFoType === 2 ? freeClaValRef.current!.getValue()! : null,
			conditionId: selFoType === 2 ? condData[selCondIndex]._id : null,
			conditionValue: selFoType === 2 ? cVal : null,
			variations: varsData.map(vd => ({
				customiIds: vd.customiIds.map((v, i) => custData[i]._id),
				addonIds: vd.addonIds.map((v, i) => adoData[i]._id),
				name: vd.name,
				isDefault: vd.isDefault,
				price: vd.price,
				discountPrice: vd.discountPrice,
				hasTax: vd.hasTax,
				taxId: vd.hasTax ? vd.taxId : null,
				useOverallTax: vd.useOverallTax,
				availability: vd.availability,
				servingInfo: vd.servingInfo,
				calorieCount: vd.calorieCount,
				portionSize: vd.portionSize,
				preparationTime: vd.preparationTime,
				hasOffer: vd.hasOffer,
				offerValidity: vd.offerValidity,
				offerNote: vd.offerNote,
				hasStock: vd.hasStock,
				noOfStock: vd.noOfStock,
				ingredients: vd.ingredients.map((ig, j) => ({
					note: ig.note,
					matId: ig.matId,
					isWeight: ig.isWeight,
					weight: ig.weight,
					nos: ig.nos,
					wastage: ig.wastage,
				})),
			})),
		}));

		if (photoImg.current) {
			const images = [];
			for (const v of photoImg.current.selFiles()) {
				images.push(v.file!);
			}

			formData.append('images', new Blob(images));
		}

		await new FoodsService().create(formData).then(val => {
			if (relToast.current) {
				relToast.current.trigger(val.hasError, val.hasError ? val.errorMsg! : val.res!.message);
			}
		});
	};

	const onFill = (d: Variations) => {
		if (!isLoading) {
			if (nameVarField.current) {
				nameVarField.current.initValue(d.name);
			}

			if (priceVarField.current) {
				priceVarField.current.initValue(d.price.toString());
			}

			setHasStock(d.hasStock);

			if (stockVarField.current) {
				stockVarField.current.initValue(d.noOfStock.toString());
			}

			if (serInfoVarField.current) {
				serInfoVarField.current.initValue(d.servingInfo.toString());
			}

			if (caloCouVarField.current) {
				caloCouVarField.current.initValue(d.calorieCount.toString());
			}

			if (porSizeVarField.current) {
				porSizeVarField.current.initValue(d.portionSize.toString());
			}

			if (prepTimVarField.current) {
				prepTimVarField.current.initValue(d.preparationTime.toString());
			}

			setSelCusts(d.customiIds.map((v, i) => i));
			setSelAdos(d.addonIds.map((v, i) => i));

			setTaxMode(d.hasTax ? 0 : d.useOverallTax ? 1 : 2);
			setSelTax(d.hasTax ? taxData.map(v => v._id).indexOf(d.taxId) : -1);

			if (availRef.current) {
				availRef.current.setValue(d.availability);
			}

			setHasOffer(d.hasOffer);
			if (disPriceVarField.current) {
				disPriceVarField.current.initValue(d.discountPrice.toString());
			}

			if (offNoteVarField.current) {
				offNoteVarField.current.initValue(d.offerNote);
			}

			if (offValiRef.current) {
				offValiRef.current.setValue(d.offerValidity);
			}

			setSelIngred(d.ingredients.map(v => ({
				note: v.note,
				matName: matData.find(k => k._id === v.matId)!.name,
				matId: v.matId,
				isWeight: v.isWeight,
				weight: v.weight,
				nos: v.nos,
				wastage: v.wastage,
			})));
		}
	};

	return (
		<RestaurantContext.Consumer>{
			rest => (<div className='AddFood'>
				<div className={isToolHide ? 'AddFood-tools-hide' : 'AddFood-tools'}>
					<div className='AddFood-toast'>
						<RelToast ref={relToast}></RelToast>
					</div>
					<div className='AddFood-container-row' style={{justifyContent: 'flex-end', alignItems: 'center'}}>
						{loadingScreen()}
						<Space size={25} isAutoResize={false}></Space>
						<OutlineButton onClick={() => {
							void onAddFood(rest.data);
						}} isLoading={isLoading} label={'Add Food'} padTopBottom='8px' btnColor='rgb(70, 125, 203)'></OutlineButton>
					</div>
				</div>
				<div onScroll={onScroll} className='AddFood-content'>
					<div className='horlistview-wrapper'>
						<HorButtons ref={restTypesRef} data={fooTypeData()} adapter={function (i: number, item: string | number): JSX.Element {
							const itemData = item as string;
							return <div className='listview-item-adapter'>
								<div className='listview-item-adapter-card'>
									<label className='horlistview-item-adapter-title'>{`${itemData}`}</label>
									<Space size={5} isAutoResize={true}></Space>
									<RadioButton title='' index={i} selIndex={selFoType} onClick={(r: number) => {
										setSelFoType(i);
									}}></RadioButton>
								</div>
							</div>;
						}} adapterHeight={35} selItemIndex={selFoType} onSelectItem={(i: number) => {
							setSelFoType(i);
						}} />
					</div>
					<Space size={betWeenContainerSize} isAutoResize={false}></Space>
					<div className='AddFood-container-row'>
						<TextField ref={nameField} isLoading={isLoading}
							label={'Name'} styles={TextFieldStyles.indiTitle} placeholder='I.e: Chicken Biriyani'
							inputType={TextFieldInputTypes.name}></TextField>
						<Space size={15} isAutoResize={false}></Space>
						<TextField ref={noteField} isLoading={isLoading}
							label={'Note'} styles={TextFieldStyles.indiTitle} placeholder='I.e: Traditional authentic taste'
							inputType={TextFieldInputTypes.name}></TextField>
					</div>
					<Space size={betWeenContainerSize} isAutoResize={false}></Space>
					<div className='AddFood-container-row'>
						<div className='AddFood-container-column'>
							<label className='AddFood-label'>Select the restaurant (food will available only on selected restaurant)</label>
							<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
							{rest.isLoading ? (<div className='listview-wrapper'>
								<ListView dummy={3} adapter={function (i, item): JSX.Element {
									return <div className='listview-item-adapter'>
										<label className='listview-item-adapter-index-load'>00</label>
										<Space size={5} isAutoResize={true}></Space>
										<div className='listview-item-adapter-card-load'>
											<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
										</div>
									</div>;
								}} selItemIndex={-1} adapterHeight={35} />
							</div>) : (<div className='listview-wrapper'>
								<ListView ref={restRef} data={rest.data} adapter={function (i: number, item: RestaurantModel | number): JSX.Element {
									const itemData = item as RestaurantModel;
									return <div className='listview-item-adapter'>
										<label className='listview-item-adapter-index'>{i + 1}</label>
										<Space size={5} isAutoResize={true}></Space>
										<div className='listview-item-adapter-card'>
											<label className='listview-item-adapter-title'>{`${itemData.name}`}</label>
											<RadioButton title='' index={i} selIndex={selRest} onClick={(b: number) => {
												if (!isLoading) {
													setSelRestType([]);
													setSelRest(b);
												}
											}}></RadioButton>
											<Space size={5} isAutoResize={true}></Space>
										</div>
									</div>;
								}} adapterHeight={35} selItemIndex={selRest} onSelectItem={(i: number) => {
									if (!isLoading) {
										setSelRestType([]);
										setSelRest(i);
									}
								}} />
							</div>)}
						</div>
						<Space size={5} isAutoResize={true}></Space>
						<div className='AddFood-container-column'>
							<label className='AddFood-label'>Select the services (food will available only on selected service)</label>
							<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
							{!rest.isLoading && selRest !== -1 && (<div className='listview-wrapper'>
								<ListView ref={restTypRef} data={rest.data[selRest].restTypes} adapter={function (i: number, item: {
									_id: string;
									name: string;
								} | number): JSX.Element {
									const itemData = item as {
										_id: string;
										name: string;
									};
									return <div className='listview-item-adapter'>
										<label className='listview-item-adapter-index'>{i + 1}</label>
										<Space size={5} isAutoResize={true}></Space>
										<div className='listview-item-adapter-card'>
											<label className='listview-item-adapter-title'>{itemData.name}</label>
											<CheckBox title='' initVal={selRestType.includes(i)} ovrVal={selRestType.includes(i)} onClick={(b: boolean) => {
												if (!isLoading) {
													if (selRestType.includes(i)) {
														setSelRestType(selRestType.filter(v => v !== i));
													} else {
														setSelRestType([...selRestType, i]);
													}
												}
											}}></CheckBox>
											<Space size={5} isAutoResize={true}></Space>
										</div>
									</div>;
								}} adapterHeight={35} selItemIndex={selRestType} onSelectItem={(i: number) => {
									if (!isLoading) {
										if (selRestType.includes(i)) {
											setSelRestType(selRestType.filter(v => v !== i));
										} else {
											setSelRestType([...selRestType, i]);
										}
									}
								}} />
							</div>)}
						</div>
					</div>
					<Space size={betWeenContainerSize} isAutoResize={false}></Space>
					<div className='AddFood-container-column'>
						<label className='AddFood-label'>Menus</label>
						<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
						{isMenuLoading ? (<div className='listview-wrapper'>
							<ListView dummy={5} adapter={function (i, item): JSX.Element {
								return <div className='listview-item-adapter'>
									<label className='listview-item-adapter-index-load'>00</label>
									<Space size={5} isAutoResize={true}></Space>
									<div className='listview-item-adapter-card-load'>
										<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
									</div>
								</div>;
							}} selItemIndex={-1} adapterHeight={35} />
						</div>) : (<div className='listview-wrapper'>
							<ListView ref={menuRef} data={menuData} adapter={function (i: number, item: MenusModel | number): JSX.Element {
								const itemData = item as MenusModel;
								return <div className='listview-item-adapter'>
									<label className='listview-item-adapter-index' style={{width: '2%'}}>{i + 1}</label>
									<Space size={5} isAutoResize={true}></Space>
									<div className='listview-item-adapter-card'>
										<label className='listview-item-adapter-title'>{itemData.name}</label>
										<CheckBox title='' initVal={selMenus.map(v => v).includes(i)} ovrVal={selMenus.map(v => v).includes(i)} onClick={(b: boolean) => {
											if (!isLoading) {
												if (selMenus.map(v => v).includes(i)) {
													setSelMenus(selMenus.filter(v => v !== i));
												} else {
													setSelMenus([...selMenus, i]);
												}
											}
										}}></CheckBox>
										<Space size={5} isAutoResize={true}></Space>
									</div>
								</div>;
							}} adapterHeight={35} selItemIndex={selMenus.map(v => v)} onSelectItem={(i: number) => {
								if (!isLoading) {
									if (selMenus.map(v => v).includes(i)) {
										setSelMenus(selMenus.filter(v => v !== i));
									} else {
										setSelMenus([...selMenus, i]);
									}
								}
							}} />
						</div>)}
					</div>
					{comboOrFree()}
					<Space size={betWeenContainerSize} isAutoResize={false}></Space>
					{coalAndCategory()}
					<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
					<div className='AddFood-container-column'>
						<ImageImport ref={photoImg} label={'Food Images (max 5)'} totalImages={5} isLoading={isLoading}></ImageImport>
					</div>
					<Space size={betWeenContainerSize} isAutoResize={false}></Space>
					<div className='AddFood-container-column'>
						<label className='AddFood-label'>Variations</label>
						<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
						<label className='AddFood-label-sub'>* Variations used to define diferent varities of a food</label>
						<label className='AddFood-label-sub'>* Similar like a pizza has multiple sizes (small, medium, extra large)</label>
						<label className='AddFood-label-sub'>* If no any varities for a food still you have to add an default variation for a food</label>
						<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
						<div className='listview-wrapper' style={{height: '100px'}}>
							{varsData.length === 0
								? <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
									<label className='AddFood-label-sub' style={{textAlign: 'center'}}>Add atleast a variation</label>
								</div>
								: <ListView ref={variationRef} data={varsData} adapter={function (i: number, item: Variations | number): JSX.Element {
									const itemData = item as Variations;
									return <div className='Addfood-horlist-item-adapter' style={itemData.isDefault ? {border: '1px solid green'} : {}}>
										<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
											<label className='listview-item-adapter-index' style={{width: '100%'}}>{i + 1}</label>
											<Space size={10} isAutoResize={false}></Space>
											<IconButton icon={delIcon.normal()} onClick={() => {
												setVarsData(prevData => prevData.filter((item, j) => j !== i));
											}}></IconButton>
											<Space size={5} isAutoResize={false}></Space>
											<IconButton icon={pasteIcon.normal()} onClick={() => {
												onFill(varsData[i]);
											}}></IconButton>
										</div>
										<Space size={5} isAutoResize={false}></Space>
										<label className='listview-item-adapter-title'>{`${itemData.name}`}</label>
										<Space size={5} isAutoResize={false}></Space>
										<label className='listview-item-adapter-title'>{`${itemData.price}`}</label>
									</div>;
								}} adapterHeight={90} isHorizontal={true} />}
						</div>
						<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
						<label className='AddFood-label-sub'>* default used to set the variaiton first visible to the user</label>
						<label className='AddFood-label-sub'>* only one variaiton can set to default in the food</label>
						<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
						{variForm()}
					</div>
					<Space size={50} isAutoResize={false}></Space>
				</div>
			</div >)}</RestaurantContext.Consumer>
	);
};

export default AddFood;
